.App {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  flex-direction: column;
  font-size: larger;
  overflow-x: hidden;
  overflow-y: scroll;
}

.bgimg {
  filter: blur(10px);
  min-width: 100vw;
  min-height: 100vh;
  height: 120%;
  transform: scale(1.3);
  object-fit: cover;
  position: absolute;
  align-self: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
}

.bgvideo {
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  object-fit: cover;
  position: absolute;
  align-self: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
}

.ownedPepes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1rem;
}

/* Define a container for the grid */
.image-container {
  min-width: 50%; /* maximum width of 50% of the screen */
  margin: 1 auto; /* center the grid container */
  word-wrap: break-word; /* allow for wrapping of grid items */
  margin-top: 1rem;
}

.image-container img {
  width: 250px;
  object-fit: cover;
  border-radius: 25px;
  margin-left: 1rem;
  cursor: pointer;
}

.eGirl-image-container {
  min-width: 50%; /* maximum width of 50% of the screen */
  margin: 1 auto; /* center the grid container */
  word-wrap: break-word; /* allow for wrapping of grid items */
  margin-top: 1rem;
}

.eGirl-image-container img {
  width: 250px;
  object-fit: cover;
  border-radius: 25px;
  margin-left: 1rem;
  cursor: pointer;
}

:hover.UkiyoPepeImage {
  transform: scale(1.1);
}

:hover.UkiyoeGirlImage {
  transform: scale(1.1);
}

.mintBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mintMath {
  padding-left: 1rem;
  padding-right: 1rem;
}

:hover.mintMath {
  cursor: pointer;
  transform: scale(1.7);
}

.horizontalButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.sorryText {
  margin-top: 1rem;
  max-width: 50%;
}

.sadge {
  max-width: 2rem;
}

.takumiPlayer {
  margin: 1rem;
}

.playButton {
  max-width: 3rem;
}

:hover.playButton {
  cursor: pointer;
  transform: scale(1.7);
  filter: blur(3px);
}

.contractLinks {
  text-transform: none;
  display: flex;
  flex-direction: row;
}

.contractText {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  text-align: center;
  text-decoration: none;
  color: inherit;
  font-weight: thin;
  margin-top: 1rem;
  text-transform: none;
}

.contractText2 {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  text-align: center;
  text-decoration: none;
  color: inherit;
  font-weight: thin;
  text-transform: none;
  margin-bottom: 1rem;
}

:hover.contractText {
  cursor: pointer;
  transform: scale(1.2);
  filter: blur(1px);
}

:hover.contractText2 {
  cursor: pointer;
  transform: scale(1.2);
  filter: blur(1px);
}

.notWhitelistedText {
  max-width: 80%;
  text-transform: none;
  margin-top: -.5rem;
}

.mintAmount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: center;
  max-width: 100%;
  text-transform: none;
}

.mintAmountButtons {
  border-radius: 50px;
  height: 2rem;
  width: 4rem;
  background-color: rgba(0, 0, 0, 0.707);
  color: white;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  backdrop-filter: blur(25px);
  border: none;
  margin-left: .5rem;
  margin-right: .5rem;
  margin-bottom: 1rem;
}

:hover.mintAmountButtons {
  justify-content: center;
  border-radius: 25px;
  color: black;
  border: none;
  background-color: rgba(255, 255, 255, 0.444);
  cursor: pointer;
  transform: scale(1.1);
}

.mintButton {
  margin-bottom: 1rem;
  justify-content: center;
  position: center;
  border-radius: 50px;
  width: 6rem;
  padding: 1rem;
  font-size: large;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0.707);
  color: white;
  cursor: pointer;
  backdrop-filter: blur(25px);
  border: none;
}

.nftButton {
  margin-bottom: 1rem;
  justify-content: center;
  position: center;
  border-radius: 50px;
  width: 40%;
  min-height: 2rem;
  background-color: rgba(0, 0, 0, 0.707);
  color: white;
  cursor: pointer;
  backdrop-filter: blur(25px);
  border: none;
}

:hover.mintButton {
  justify-content: center;
  border-radius: 25px;
  color: black;
  border: none;
  background-color: rgba(255, 255, 255, 0.444);
  cursor: pointer;
  transform: scale(1.1);
}

:hover.nftButton {
  justify-content: center;
  border-radius: 25px;
  color: black;
  border: none;
  background-color: rgba(255, 255, 255, 0.444);
  cursor: pointer;
  transform: scale(1.1);
}

.greedyFrog {
  margin-top: 4rem;
}

.networkpopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: (0);
  z-index: 2;
  border-radius: 15px;
  position: fixed;
  backdrop-filter: blur(90px);
}

.pepeHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100%;
  background-color: (0);
  z-index: 2;
  position: fixed;
  border-radius: 15px;
  backdrop-filter: blur(90px);
  overflow: auto;
}

.networkButton {
  justify-content: center;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.506);
  color: white;
  cursor: pointer;
  padding: 1rem;
  backdrop-filter: blur(25px);
  border: none;
}

.showNFTButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 80%;
}

:hover.networkButton {
  justify-content: center;
  border-radius: 25px;
  color: black;
  border: none;
  background-color: rgba(255, 255, 255, 0.444);
  cursor: pointer;
}

.bgclicker {
  z-index: 10;
  min-width: 100%;
  min-height: 100%;
  fill: black;
}

.main {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  text-align: center;
  max-width: 600px;
  max-height: 80%;
  border-radius: 25px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  backdrop-filter: blur(25px);
  background-color: rgba(255, 176, 133, 0.546);
  padding: 2rem;
  margin: 5rem;
  overflow-y: scroll;
}

.txHashText {
  display: flex;
  text-align: center;
  text-decoration: none;
  color: inherit;
  font-weight: bolder;
  margin-bottom: 1rem;
}

:hover.txHashText {
  cursor: pointer;
  transform: scale(1.2);
  filter: blur(1px);
}

.avi-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  width: 100%;
  border-radius: 25px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin-left: 3rem;
}

.marketplace-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 80%;
  border-radius: 25px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  backdrop-filter: blur(25px);
  margin: 1rem;

}

.account-details {
  display: flex;
  align-items: left;
  flex-direction: column;
  text-align: left;
  max-width: 250px;
  border-radius: 25px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin-left: 2rem;
}

.Collapsible {
  border-style: none;
  font-weight: bolder;
  width: 100%;
  border-radius: 2rem;
  margin-top: 2rem;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 25px;
  cursor: pointer;
  padding: 1rem;
  margin: 1rem;
  backdrop-filter: blur(25px);
}

:hover.Collapsible {
  justify-content: center;
  border-radius: 25px;
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
}

:hover.marketplace {
  cursor: pointer;
}

.marketplace {
  border-style: none;
  border-radius: 2rem;
  color: rgba(240, 248, 255, 0.117);
}

.main::-webkit-scrollbar{
  display: none;
}

.connect {
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: medium;
}

.connectButton {
  justify-content: center;
  position: center;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.506);
  color: white;
  cursor: pointer;
  padding: 1rem;
  backdrop-filter: blur(25px);
  border: none;
  margin: 1rem;
}

:hover.connectButton {
  justify-content: center;
  border-radius: 25px;
  color: black;
  border: none;
  background-color: rgba(255, 255, 255, 0.444);
  cursor: pointer;
}

.accountText {
  display: flex;
  text-align: left;
  text-decoration: none;
  color: inherit;
  font-weight: bolder;
}

:hover.accountText {
  cursor: pointer;
  filter: blur(2px);
}

.accountButton {
  display: flex;
  text-align: center;
  text-decoration: none;
  color: inherit;
  font-weight: bolder;
}

:hover.accountButton {
  filter: blur(2px);
}

.gwei {
  max-width: 100%;
}

.block {
  max-width: 100%;
}

.getavi {
  max-height: 250px;
  border-radius: 15px;
}

.getavi2 {
  max-height: 250px;
  border-radius: 25px;
}

:hover.getavi {
  filter: blur(2px);
  cursor: pointer;
}

.audioPlayer {
  padding-bottom: 1rem;
}

.audio-wrapper {
  position: fixed;
  top: 20px;
  right: 5px;
  width: 20%;
  display: flex;

}

.gasImg { 
  height: 1rem;
  padding-left: .5rem;
  justify-content: right;
}
